<template>
  <!-- Map -->
  <gmap-map
    ref="GMapDeliveryRadiusForm"
    :center="center"
    :zoom="zoom"
    @zoom_changed="onZoomChanged"
    class="GMapDeliveryRadiusForm"
  >
    <!-- Marker -->
    <gmap-marker :position="currentPosition" />

    <!-- Circle -->
    <!-- @radius_changed="onRadiusChanged" -->
    <!-- editable -->
    <gmap-circle
      v-if="currentPosition"
      :center="currentPosition"
      :radius="radius"
    />
  </gmap-map>
</template>

<script>
export default {
  props: {
    center: {
      type: Object,
      required: true,
    },
    radius: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // References
      google: null,
      map: null,

      // Map setting
      //   center: { lat: 0, lng: 0 },
      zoom: 13,

      // Current Position
      currentPosition: this.center,
    };
  },
  methods: {
    // Map
    onZoomChanged(newZoom) {
      this.zoom = newZoom;
    },
  },
};
</script>

<style>
.GMapDeliveryRadiusForm {
  width: 100%;
  height: 400px;
}
</style>