<template>
  <div>
    <template>
      <!-- If No center, show dialog to first set a store location -->
      <b-alert :show="!canDisplayMap" variant="info" class="text-center">
        No Store location has been set. Please set your Google based location
        <a href="#" @click.prevent="setStoreLocation"><b>Here</b></a>
        to view the delivery radius on the map.
      </b-alert>

      <!-- Show Check-box, to view map or hide map -->
      <b-row>
        <b-col offset-lg="3">
          <b-form-checkbox
            v-if="canDisplayMap"
            v-model="showMap"
            class="mb-2 ml-2"
          >
            Show map
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <!-- Radius Input -->
    <b-row>
      <b-col offset-lg="3" lg="5">
        <b-row>
          <b-col cols="9">
            <b-form-group
              description="Use Scroll to increment/decrement delivery radius"
              label-cols="3"
              label="Radius"
              label-for="radiusString"
            >
              <b-input-group
                :prepend="radiusMin.toString()"
                :append="radiusMax.toString()"
              >
                <b-form-input
                  type="range"
                  id="radiusString"
                  v-model="radiusString"
                  :min="radiusMin"
                  :max="radiusMax"
                  trim
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              v-model="radiusString"
              :min="radiusMin"
              :max="radiusMax"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Display Map -->
    <GMapDeliveryRadiusForm
      v-if="canDisplayMap"
      v-show="showMap"
      :center="center"
      :radius="radius"
    />

    <div class="text-center">
      <b-button @click.prevent="save" class="mr-2 my-2" variant="primary">
        Save
      </b-button>
      <b-button @click.prevent="cancel"> Cancel </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateDeliverySettings } from "../../../../apis/merchant";
import GMapDeliveryRadiusForm from "../../../../components/store/GMapDeliveryRadiusForm.vue";

const RADIUS_MIN = 200;
const RADIUS_MAX = 5000;

export default {
  components: { GMapDeliveryRadiusForm },
  props: {
    radiusInMeters: {
      type: Number,
      required: false,
      default: RADIUS_MIN,
    },
  },
  created() {
    this.initDeliveryForm();
  },
  data() {
    return {
      // Circle
      radiusString: this.radiusInMeters.toString(),
      radiusMin: RADIUS_MIN,
      radiusMax: RADIUS_MAX,

      // Map setting
      showMap: false,
      center: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    radius() {
      // in meters
      if (this.radiusString) {
        const currentValue = Number(this.radiusString);
        // If greater, return max
        if (currentValue > this.radiusMax) {
          return this.radiusMax;
        }
        // If lesser, return min
        if (currentValue < this.radiusMin) {
          return this.radiusMin;
        }
        // current value
        return currentValue;
      }
      return this.radiusMin;
    },
    canDisplayMap() {
      return this.center ? true : false;
    },
  },

  methods: {
    ...mapActions({
      initSettings: "merchant/deliverySettings/initSettings",
      setDeliverySettings: "merchant/deliverySettings/setSettings",
    }),
    initDeliveryForm() {
      this.initSettings(this.token).then((data) => {
        const radius = data.radius;
        this.radiusString = radius ? radius : RADIUS_MIN;
        const lat = data.latitude;
        const lng = data.longitude;
        if (lat && lng) {
          this.center = { lat, lng };
        }
      });
    },
    setStoreLocation() {
      this.$router.push({ name: "StoreAddressView" });
    },
    save() {
      // make API call to update delivery setting for area
      let areaForm = {};
      if (this.center) {
        areaForm = {
          latitude: this.center.lat,
          longitude: this.center.lng,
          radius: this.radius,
        };
      }
      updateDeliverySettings(null, areaForm, this.token).then((data) => {
        // update settings
        this.setDeliverySettings(data);
        // go back
        this.cancel();
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
